*,
*::after,
*::before {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

a {
    text-decoration: none !important;
    color: #000 !important;
    font-weight: 300;
}

html {
    font-size: 62.5% !important;
}

ul {
    margin: 0% !important;
}

.navbar input[type="checkbox"],
.navbar .hamburger-lines {
    display: none;
}

/* .container{
    max-width: 1200px;
    width: 90%;
    margin: auto;
} */

.navbar {
    position: fixed !important;
    width: 100%;
    background: transparent;
    top: 0%;
    color: #000;
    opacity: 1;
    z-index: 100;
}

.navbar.scrolled {
    background: #fffcf7ff;
    box-shadow: 0.00px 0.00px 20px 0px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, .1);
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    height: 90px;
    align-items: center;
}

.menu-items {
    order: 2;
    display: flex;
}

.logo {
    order: 1;
    font-size: 4rem;
    cursor: pointer;
}

.menu-items li {
    list-style: none;
    margin-left: 1.8rem;
    font-size: 1.5rem;
    transition: color 0.3s ease-in-out;
}

.menu-items li:hover {
    color: #0077b8ff;
    cursor: pointer;
}

@media (max-width: 768px) {
    .navbar {
        opacity: 1;
    }

    .navbar-container input[type="checkbox"],
    .navbar-container .hamburger-lines {
        display: block;
    }

    .navbar-container {
        display: block;
        position: relative;
        height: 64px;
    }

    .navbar-container input[type="checkbox"] {
        position: absolute;
        display: block;
        height: 32px;
        width: 30px;
        top: 20px;
        right: 15px;
        z-index: 5;
        opacity: 0;
        cursor: pointer;
    }

    .navbar-container .hamburger-lines {
        display: block;
        height: 28px;
        width: 35px;
        position: absolute;
        top: 20px;
        right: 15px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .navbar-container .hamburger-lines .line {
        display: block;
        height: 4px;
        width: 100%;
        border-radius: 10px;
        background: #333;
    }

    .navbar-container .hamburger-lines .line1 {
        transform-origin: 0% 0%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar-container .hamburger-lines .line2 {
        transition: transform 0.2s ease-in-out;
    }

    .navbar-container .hamburger-lines .line3 {
        transform-origin: 0% 100%;
        transition: transform 0.3s ease-in-out;
    }

    .navbar .menu-items {
        padding-top: 100px;
        background: #fffcf7ff;
        height: 100vh;
        max-width: 100vw;
        width: 98vw;
        transform: translate(-150%);
        display: flex;
        flex-direction: column;
        margin-right: -40px;
        transition: transform 0.5s ease-in-out;
    }

    .navbar .menu-items li {
        margin-bottom: 1.8rem;
        font-size: 4rem;
        font-weight: 500;
        text-align: center;
    }

    .logo {
        position: absolute;
        top: 10px;
        left: 20px;
        font-size: 2.5rem;
    }

    .navbar-container input[type="checkbox"]:checked~.menu-items {
        transform: translateX(0);
    }

    .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
        transform: rotate(45deg);
    }

    .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
        transform: scaleY(0);
    }

    .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
        transform: rotate(-45deg);
    }

}

@media (max-width: 500px) {
    .navbar-container input[type="checkbox"]:checked~.logo {
        display: none;
    }
}

/* footer */
.footer-wrap {
    width: 100%;
    height: 500px;
    position: relative;
    overflow: hidden;
}

.footer-overlay {
    position: absolute;
    top: 40px;
    z-index: 1;
    width: 100%;
}

.footer {
    padding: 100px 40px !important;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}

.m-link {
    margin-top: 60px;
}

.link {
    transition: all 0.3s ease-in-out;
}

.link:hover {
    color: #0077b8ff;
    cursor: pointer;
}

.foot-links-box {
    padding: 20px 40px !important;
}

.footer-links {
    display: flex;
    justify-content: end;
}

.footer-links i {
    margin: 0px 5px;
    color: #000;
    cursor: pointer;
}

@media(max-width:578px) {
    .footer-links {
        justify-content: center;
        margin-top: 30px !important;
    }

    .footer-copyright {
        text-align: center;
    }
}

/* Slides.css */
.custom-carousel {
    position: relative;
    height: 450px;
    color: #000;
    background-color: #fffcf7ff;
    padding: 60px;
    border-radius: 20px;
    overflow: hidden;
}

.carousel-inner {
    display: flex;
    transition: transform 1s ease-in-out;
}

.carousel-item {
    min-width: 100%;
}

.custom-dots {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 8px;
    z-index: 1;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #000;
    border: 1px solid #000;
    border-radius: 50%;
    cursor: pointer;
}

.dot.active {
    background-color: transparent;
}

.slide-margin {
    margin: 40px auto;
}

.user-img img {
    border-radius: 50%;
    margin-right: 20px;
}

.fade-in {
    animation: fadeIn 1.5s ease-in-out;
}

/* contact form */
.contact-form {
    text-align: center;
}

.contact-form .font-35 {
    line-height: 130%;
    margin: 20px auto;
}
.input-form{
    margin: 40px auto;
}

/* Jobs */

.jobs{
    text-align: center;
}
.jobs .font-30{
    margin: 30px auto;
}

/* Inputs */
.form-group {
    margin: 0 0 20px 0;
}

.form-group p {
    font-size: 1.2rem;
    font-weight: 300;
}

.form-control {
    border: 1px solid #000 !important;
    border-radius: 10px !important;
    font-size: 1.5rem !important;
    background-color: #fffcf7ff !important;
    color: #000 !important;
    padding: 14px !important;
}

.form-control:hover {
    border: 2px solid #000 !important;
}


.form-control:focus, .form-control:focus-visible {
    border-color: #000 !important;
    box-shadow: none !important;
    border: 2px solid #000 !important;
}

.form-group label {
    font-size: 1.6rem;
    color: #000;
    font-weight: 400;
    margin-bottom: 5px;
}
.submit-btn button{
    width: 100%;
    margin-top: 20px !important;
}
select{
    background: #0000;
    background-image: url('../images/down-arrow.png');
    background-position: right 20px center;
    background-repeat: no-repeat;
    background-size: 16px;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@media(max-width:992px) {
    html {
        font-size: 58% !important;
    }

    .row-left {
        justify-content: right !important;
        flex-direction: column-reverse;
    }
    .page-mid-content img {
        width: 95% !important;
    }
}

@media(max-width:768px) {
    html {
        font-size: 55% !important;
    }

    .footer {
        padding: 40px !important;
    }

    .page-mid-content img {
        width: 90% !important;
    }

    .box-round img, .box-round-left img {
        width: 90%;
    }

    .box-round, .box-round-left {
        min-height: 400px !important;
        margin: 20px 0px;
    }
}

@media(max-width:558px) {
    html {
        font-size: 44% !important;
    }

    .footer {
        padding: 40px !important;
    }

    .box-round, .box-round-left {
        min-height: 300px !important;
        margin: 20px 0px;
    }

    .logo {
        font-size: 5rem;
    }

    .custom-carousel {
        height: 400px;
        padding: 30px;
    }

    .user-img img {
        width: 60px;
    }

    .font-55 {
        font-size: 4.5rem !important;
    }

    .font-45 {
        font-size: 4rem !important;
    }
    .font-70{
        font-size: 5rem !important;
    }

    .video-hero {
        height: 650px !important;
    }
    .form-control {
        padding: 8px !important;
    }
}