body {
    overflow-x: hidden;
}

#root {
    background-color: #fffcf7ff;
    font-family: Arial, Helvetica, sans-serif !important;
}

.app {
    opacity: 0;
    transition: opacity 2s ease-in-out;
}

.app.fade-in {
    opacity: 1;
}

.font-70 {
    font-size: 7rem;
}

.font-30 {
    font-size: 3rem;
}

.font-35 {
    font-size: 3.5rem;
}

.font-45 {
    font-size: 4.5rem;
    line-height: 130%;
}

.font-55 {
    font-size: 5.5rem;
    line-height: 130%;
}

.font-25 {
    font-size: 2.5rem;
}

.font-20 {
    font-size: 2rem;
}

.font-18 {
    font-size: 1.8rem;
}

.font-17 {
    font-size: 1.7rem;
}

.font-14 {
    font-size: 1.4rem;
}

.video-hero {
    width: 100%;
    height: 600px;
    position: relative;
    overflow: hidden;
}

video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.content-overlay {
    padding: 120px 20px !important;
    z-index: 1;
    text-align: center;
    margin: 0px auto;
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translate(-50%, 20px);
}

.content-overlay .m-heading {
    margin: 15px auto;
}

.content-overlay .mt-heading {
    margin-top: 25px;
}

.page-mid-content {
    background-image: url('../images/page-center-bg.jpg');
    background-size: cover;
    background-repeat: no-repeat;
    padding: 60px 20px !important;
    text-align: center;
}

.page-mid-content .padding-para {
    padding: 20px;
    font-weight: 400;
}

.page-mid-content img {
    width: auto;
}

.page-main-content {
    padding: 60px 0px;
    background-color: #fffcf7ff;
}

.page-main-content .row {
    margin: 60px auto;
    justify-content: space-between;
}

.page-content-heading {
    text-align: center;
    padding: 20px !important;
}

.m-heading {
    margin: 25px auto;
}

.box-round, .box-round-left {
    position: relative;
    border-radius: 20px;
    min-height: 600px;
}

.bg-blue {
    background: linear-gradient(#afdaf0ff, #0796e3ff);
}

.bg-pink {
    background: linear-gradient(#ffebf6ff, #ffd4f4ff);
    
}

.bg-green {
    background: linear-gradient(#f0fac8ff, #cef261ff);
    
}

.box-round img {
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(0%, -50%);
}

.box-round-left img {
    border-radius: 20px;
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(0%, -50%);
}

/*  Button */

button {
    border-radius: 10px !important;
    background-color: #000;
    color: #fffcf7ff;
    font-weight: 400;
    padding: 10px;
    font-size: 1.7rem !important;
    border: 1px solid transparent;
    transition: all 0.3s ease-in-out;
    margin-top: 100px !important;
}

button:hover {
    color: #000;
    border: 1px solid #000;
    background-color: #cef261ff;
}

/* Slider */

.slider-wrap {
    background-color: #0796e3ff;
    border-radius: 20px;
    padding: 60px 60px !important;
    color: #fffcf7ff;
}

/* Jobs */

.job-main-img img {
    border-radius: 20px;
}

@media(max-width:558px) {
    button {
        padding: 5px;
        margin-top: 50px !important;
    }
}